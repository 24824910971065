/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import { useRouter } from 'next/dist/client/router';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cookieCutter from 'cookie-cutter';
import { getUserDetailsStart } from 'store/user/actions';

import fetchApi from 'hooks/fetchApi';
import useReferrerData from 'hooks/useReferrerData';
import API_NAME from 'services/api';
import { wrapper } from 'store/store';
import { Layout, MetaTags, RedirectLoading } from 'components';
import { routesPath } from 'utils/constants/routePath';
import { hasValidObjectId } from 'utils/storageUtils';
import { parseJsonValue } from 'utils/helpers';
import localizeMoment from 'utils/localizeMoment';
import { APP_CONFIG } from 'utils/constants';
import 'utils/i18n';
import { clearSidebarStore } from 'store/sideBarControler/actions';

import * as ga from 'lib';
import ThemeProvider from 'theme';
import auths from 'utils/auths';
import Error from 'containers/Error';
import i18n from 'i18next';
import ClubManager from 'utils/clubManager';
import Scroll from '../hooks/scroll';
import { GoogleOAuthProvider } from '@react-oauth/google';

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
  }

  html {
    scroll-behavior: auto;
  }

  body {
    font-family: 'Poppins';
    background: #f6f6f6;
    padding: 0;
    width:100%;
    height:100%;
    overflow: ${({ isSidebarOpen }) => (isSidebarOpen ? 'hidden' : 'visible')};
    margin: 0;
  }

  img {
    /* background: url('/assets/img/tireTrace.png') no-repeat; */
    background-size: cover;
    color: transparent;
  }
`;

function App({ Component, pageProps, canonical }) {
  const [featchAnonimus, setFeatchAnonimus] = useState('undefined');
  // const { t, i18n } = useTranslation()
  const [featchAnonimusId, setFeatchAnonimusId] = useState(null);
  const router = useRouter();
  const dispatch = useDispatch();
  const { action, loading } = fetchApi();
  const queryScreen = router?.query?.screen;
  useReferrerData();

  let tutorialIsActive;
  if (typeof window !== 'undefined') {
    tutorialIsActive = localStorage.getItem('tutorialIsActive');
    tutorialIsActive =
      tutorialIsActive === 'undefined' ? '' : parseJsonValue(tutorialIsActive);
  }
  const { route, locale } = router;

  const token = useMemo(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('token') || featchAnonimus;
    }
    return 'undefined';
  }, [featchAnonimus, route]);
  const isWindowDefined = typeof window !== 'undefined';
  const userId = useMemo(() => {
    if (isWindowDefined) {
      return localStorage.getItem('user_id') &&
        hasValidObjectId(parseJsonValue(localStorage.getItem('user_id')))
        ? parseJsonValue(localStorage.getItem('user_id'))
        : featchAnonimusId;
    }
    return 'undefined';
  }, [featchAnonimusId, route, isWindowDefined]);
  console.log('user id is', userId, isWindowDefined);
  const { isSidebarOpen } = useSelector((state) => state.sideBarControler);
  const { token: JWT } = useSelector((state) => state.login);

  useEffect(() => {
    if (
      !localStorage.getItem('user_id') ||
      (localStorage.getItem('user_id') &&
        !hasValidObjectId(parseJsonValue(localStorage.getItem('user_id'))))
    ) {
      localStorage.setItem('RoadStr-appLanguage', locale ?? 'en');
      i18n.changeLanguage(locale ?? 'en');
    }
  }, [locale]);

  useEffect(() => {
    const isAnonymousAvailablePage = [
      '/p',
      '/v',
      '/e',
      '/g',
      '/u',
      '/r',
      '/s',
      '/b',
      '/donate',
      '/authentication',
      '/terms_of_services',
      '/privacy_policy',
      '/magic_link',
      '/passenger-waiver',
    ].find((el) => route?.includes(el));

    const isAuthenticate = token && token !== 'undefined';

    const isServiceUser = isAuthenticate
      ? userId === process.env._ANONYMOUS_ID
      : false;

    const isActiveTutorial = !isServiceUser ? tutorialIsActive : false;

    const isPreAuthPageWithServiceUser =
      isAuthenticate && isServiceUser ? !!isAnonymousAvailablePage : false;
    const isSpacialPage = isPreAuthPageWithServiceUser && isServiceUser;

    if (isActiveTutorial && route !== routesPath.tutorial) {
      router.push(`${routesPath.tutorial}`);
    }

    if (!isAnonymousAvailablePage && !isAuthenticate) {
      router.push(`${routesPath.authentication}`);
    }

    if (
      isAuthenticate &&
      !isServiceUser &&
      route === routesPath.authentication
    ) {
      router.push(`${routesPath.feedPath}`);
    }

    if (typeof window !== 'undefined') {
      const cookies = cookieCutter.get('tsec');
      if (
        (cookies === undefined || cookies === 'undefined') &&
        token !== 'undefined' &&
        token
      ) {
        cookieCutter.set('tsec', JSON.parse(token), { path: '/' });
      }
    }
  }, [route, token]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localizeMoment();

      window.onpopstate = () => {
        dispatch(clearSidebarStore());
      };
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };

    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    dispatch(getUserDetailsStart(userId));
    const fetchUser = async () => {
      try {
        if (userId) {
          const res = await action(API_NAME.USER_IDENTITY, {
            params: { userId },
          });
          if (typeof window !== 'undefined') {
            localStorage.setItem('user_data', JSON.stringify(res));
            if (res?.data?._id) {
              res?.data?.tutorialIsActive
                ? localStorage.setItem('tutorialIsActive', true)
                : localStorage.removeItem('tutorialIsActive');
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (userId !== process.env._ANONYMOUS_ID) {
      fetchUser();
    }
  }, [userId]);

  const renderLayout = useMemo(() => {
    if ((!token || token === 'undefined') && !!loading[API_NAME.AUTH_LOGIN]) {
      return (
        <div>
          <RedirectLoading />
          <Component {...pageProps} key={router.asPath} />
        </div>
      );
    }

    // Skip all app-wide layouts/logic for webview routes
    if (router.pathname.startsWith('/webview')) {
      return <Component {...pageProps} />;
    }

    if (!token || token === 'undefined') {
      return (
        <>
          <GlobalStyle isSidebarOpen={isSidebarOpen} />
          <Layout token={token}>
            <Component {...pageProps} key={router.asPath} />
          </Layout>
        </>
      );
    }

    return (
      <>
        <GlobalStyle isSidebarOpen={isSidebarOpen} />

        <Layout token={token}>
          <Component {...pageProps} key={router.asPath} />
        </Layout>
      </>
    );
  }, [token, router, isSidebarOpen, loading[API_NAME.AUTH_LOGIN]]);
  return (
    <ThemeProvider>
      <GoogleOAuthProvider clientId={`${process.env._GOOGLE_CLIENT_ID}`}>
        <Error staticData={pageProps?.staticData}>
          <MetaTags
            data={pageProps?.staticData?.data}
            pagePath={route}
            canonical={canonical}
          />
          {renderLayout}
          <ClubManager />
          <Scroll />
        </Error>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

App.propTypes = {
  pageProps: PropTypes.any,
  Component: PropTypes.elementType,
  canonical: PropTypes.any,
};

App.getInitialProps = ({ ctx }) => {
  const DOMAIN = `https://app.${APP_CONFIG.appname}.io`;
  const { asPath } = ctx;
  const getPath = asPath?.split('?')[0];
  const canonical = DOMAIN + getPath;

  return {
    canonical,
  };
};

export default wrapper.withRedux(App);
