/* eslint-disable camelcase */
import { getI18n } from 'react-i18next';
import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getPlaceName = ({ coordinates }) => {
  try {
    const { language } = getI18n();

    return fetch(
      `${process.env._MAPBOX_URL}/geocoding/v5/mapbox.places/${coordinates[0]},${coordinates[1]}.json?access_token=${process.env._MAPBOX_ACCESS_TOKEN}&language=${language}`
    )
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    console.log(error);
  }
};

export const searchPlace = ({ searchText, proximity = '', limit = 10 }) => {
  try {
    const { language } = getI18n();

    return fetch(
      `${
        process.env._MAPBOX_URL
      }/geocoding/v5/mapbox.places/${searchText}.json?access_token=${
        process.env._MAPBOX_ACCESS_TOKEN
      }&language=${language}&limit=${limit}${
        proximity ? `&proximity=${proximity}` : ''
      }`
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getDirectionsForDriving = ({
  coordinates,
  geometries = 'geojson',
  overview = 'full',
  steps = false,
  banner_instructions = false,
  voice_instructions = false,
  voice_units = 'metric',
  bearings,
}) => {
  try {
    const { language } = getI18n();
    const bearingsParam = bearings ? `&bearings=${bearings}` : '';

    return fetch(
      `${process.env._MAPBOX_URL}/directions/v5/mapbox/driving/${coordinates}?access_token=${process.env._MAPBOX_ACCESS_TOKEN}&language=${language}&geometries=${geometries}&overview=${overview}&steps=${steps}&banner_instructions=${banner_instructions}&roundabout_exits=true&voice_instructions=${voice_instructions}&voice_units=${voice_units}${bearingsParam}`
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const createRoute = ({ routeData }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._ROADSHARING}api/v11/roadsharing/routes`, {
      method: 'POST',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(routeData),
    }).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log('error');
  }
};

export const updateRoute = ({ routeData, routeId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._ROADSHARING}api/v11/roadsharing/routes/my/${routeId}`,
      {
        method: 'PATCH',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify(routeData),
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log('error');
  }
};

export const getRouteDetailsById = ({ routeId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._ROADSHARING}api/v11/roadsharing/routes/${routeId}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const deleteRoute = ({ routeId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._ROADSHARING}api/v11/roadsharing/routes/${routeId}`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const createRouteRating = ({ routeData, routeId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._ROADSHARING}api/v11/roadsharing/routes/${routeId}/rating`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify(routeData),
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log('error');
  }
};

export const updateRouteRating = ({ routeData, routeId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._ROADSHARING}api/v11/roadsharing/routes/${routeId}/rating`,
      {
        method: 'PATCH',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify(routeData),
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log('error');
  }
};

export const deleteRouteRating = ({ routeId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._ROADSHARING}api/v11/roadsharing/routes/${routeId}/rating`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log('error');
  }
};

export const addRouteToFavorites = ({ routeId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._ROADSHARING}api/v11/roadsharing/routes/${routeId}/favorites`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const deleteRouteFromFavorites = ({ routeId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._ROADSHARING}api/v11/roadsharing/routes/${routeId}/favorites`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const getSavedRoutes = ({ offset = 0, limit = 10, search }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${
        process.env._ROADSHARING
      }api/v11/roadsharing/routes?offset=${offset}&limit=${limit}${
        search ? `&search=${encodeURIComponent(search)}` : ''
      }`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const createSession = ({ routeId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._ROADSHARING}api/v11/roadsharing/sessions`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify({ route: routeId }),
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const postGoogleMapLink = ({ routeLink }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._ROADSHARING}api/v11/maps/parse-google-maps-link`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify({ googleMapsLink: routeLink }),
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const postParseRouteGpxFile = ({ gpxFile }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._ROADSHARING}api/v11/maps/parse-gpx-route-file`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify({ gpxFile }),
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};
