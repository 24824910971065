/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useState, useRef, useEffect, useCallback } from 'react';
import Image from 'next/image';
import { Carousel } from 'react-responsive-carousel';
import Link from 'next/link';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useRouter } from 'next/router';
import VisibilitySensor from 'react-visibility-sensor';
import { useTranslation } from 'react-i18next';

import {
  GeoLocationIcon,
  ThreeDotsIcon,
  LikeIcon,
  MessageIcon,
  ShareIcon,
  AmbasadorIcon,
  CupIcon,
  LockIcon,
  ChatIcon,
  DonorIcon,
  UsersIcon2,
  LikeOutlinedIcon,
} from 'public/assets/icons';
import { MomentFromTimeStampToNow } from 'utils/MomentFromTimeStampToNow';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useOutsideClick } from 'utils/hooks';
import { routesPath } from 'utils/constants/routePath';
import { regexMap } from 'utils/constants/regex';
import { VerifiedIcon } from 'public/assets/icons/VerifiedIcon';
import { followUser, unfollowUser } from 'services/followUser';
import { likePost, unlikePost } from 'services/likePost';
import {
  likeVehicle,
  unlikeVehicle,
  removeGroupTag,
  removeMeFromTags,
  removeMeFromCollaborators,
  getUserByName,
} from 'services';
import {
  actionTypes,
  openSidebarComments,
  openSidebarCreateEvent,
  openSideBarCreateLinkPost,
  openSideBarCreatePhotoPost,
  openSidebarChatConversation,
  openSidebarAttendEvent,
  openSidebarLikes,
  openSidebarInvolvedUsers,
} from 'store/sideBarControler/actions';
import { reportVehicleStart } from 'store/vehicle/actions';
import {
  reportPostStart,
  reportGroupStart,
  reportEventStart,
  cancelEventStart,
  deleteEventStart,
  deletePostStart,
  cancelEventSubscriptionStart,
  getEventPostsStart,
  removeEventPostGroups,
  sortEventPinnedPosts,
} from 'store/posts/actions';
import { setSearchParams, handleRedirect } from 'store/search/actions';
import { getRoomInfoStart } from 'store/chat/actions';
import { postPostViewedStart } from 'store/analytics/actions';
import { AsyncStatus } from 'utils/types';
import { getLocalStorage } from 'utils/hooks/useLocalStorage';
import { anonymousClickHandler } from 'utils/anonymousClickHandler';
import {
  DEFAULT_LINK_POST_PLACEHOLDER_IMAGE,
  InvolvedUserTypes,
} from 'utils/constants';
import { style } from 'utils/constants/style';
import { IPost } from './IPostComponent';

import {
  PostComponentWrapper,
  HederPost,
  UserPic,
  UserNameBlock,
  Dot,
  FollowingIndicator,
  BlockUserInfo,
  BlockPostTime,
  BlockGeolocation,
  ActionsPostBlock,
  CoverImg,
  CarouselDots,
  ArrowPrev,
  ArrowNext,
  SocialActivityAction,
  SocialButtonsContainer,
  SocialButtonContainer,
  OutlinedLikeIconWrap,
  SocialButtonsText,
  WheelMarkIconWrap,
  WrapPopover,
  SocialActivityBlock,
  MediaWrap,
  LinkTitle,
  LinkWrap,
  LinkText,
  LinkImageBackground,
  EventBlock,
  EventDetailsBlock,
  EventButtons,
  CarInfoBlock,
  DescriptionWrap,
  LoadingDotsWrap,
  EventPeriod,
  GroupCoverPhoto,
  GroupDescription,
  GroupDescriptionName,
  CollaboratorsHeading,
  CollaboratorsWrap,
  LinkBlockWrap,
  ThreeDotsWrap,
  CanceledEventText,
  MessageButtonWrap,
  PostHeaderWrap,
  UserIconWrap,
  EventNameText,
  EventCreatorWrap,
  EventCreatorImageWrap,
  EventCreatorName,
  GroupHeaderWrap,
  GroupNamesWrap,
  CarouselWrap,
  ClickableArea,
  EventAttendingWrap,
  EventAttendingItem,
  EventAttendingText,
} from './PostComponent.style';
import { ListUserUserActions } from '../Popover/Popover.style';
import {
  LoadingDots,
  ConfirmationModal,
  AutoCloseModal,
  ShareOptions,
  PostText,
  CollapsibleTags,
  EmptyUserProfilePhoto,
} from '..';
import { RectangleButton } from '../Buttons';
import { ColaboratorsSlider } from '../ColaboratorsSlider/ColaboratorsSlider';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';

export const PostComponent = ({
  data,
  likeClick,
  isLiked,
  variant,
  isAttending,
  onAttendingClick,
  isEventHomeTab,
  isClickable,
  isEventAdmin,
}: IPost) => {
  const {
    lat,
    lng,
    creator,
    datetime,
    text,
    media,
    mediaObjects,
    likes,
    likesCount,
    comments,
    commentsCount,
    views,
    type,
    role,
    shares,
    sharesCount,
    origin,
    _id,
    originId,
    linkImage,
    linkTitle,
    linkHost,
    link,
    location,
    event,
    users,
    vehicle,
    userData,
    isLike,
    isMine,
    group,
    privacy,
    collaborators,
    isAdmin,
    groups,
    events,
  } = data || { lat: null, lng: null, group: null };

  const { fullName, username, profilePhoto, followingStatus } = creator || {
    fullName: '',
  };

  const { isAmbassador, isVerified, isDonor } = userData || {
    isAmbassador: null,
    isVerified: null,
  };

  const { make, model, year, nickname } = vehicle || {
    make: null,
    model: null,
    year: null,
    nickname: null,
  };
  const dispatch = useDispatch();
  const router = useRouter();

  const { t } = useTranslation();

  const { action } = fetchApi();

  const [postPopoverShow, setPostPopoverShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [following, setFollowing] = useState(!!followingStatus);
  const [copiedStatus, setCopiedStatus] = useState(false);
  const [copiedImageStatus, setCopiedImageStatus] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isDeletePostModalOpen, setIsDeletePostModalOpen] = useState(false);
  const [isAfterReportModalOpen, setIsAfterReportModalOpen] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [isTagGroupRemoved, setIsTagGroupRemoved] = useState(false);
  const [isCollaboratorRemoved, setIsCollaboratorRemoved] = useState(false);
  const [isUserTagRemoved, setIsUserTagRemoved] = useState(false);
  const [isLikes, setIsLikes] = useState(isLike);
  const [likeCount, setLikeCount] = useState(likes);

  const updateLikeCount = useCallback(() => {
    setLikeCount(likes);
  }, [likes]);

  const updateIsLikeCount = useCallback(() => {
    setIsLikes(isLike);
  }, [isLike]);

  useEffect(() => {
    updateLikeCount();
  }, [updateLikeCount]);

  useEffect(() => {
    updateIsLikeCount();
  }, [updateIsLikeCount]);

  const [isAttender, setIsAttender] = useState(
    data.isAttender || event?.isAttender
  );
  const [showCancelAttendEvent, setShowCancelAttendEvent] = useState(false);

  const refActionPopover = useRef(null);
  const refShareModal = useRef(null);

  const handleLikeClick = () => {
    setIsLikes((prev) => !prev);
    if (isLikes) {
      if (variant === 'car') {
        unlikeVehicle(_id);
      } else {
        unlikePost(_id);
      }
      setLikeCount((prev) => prev - 1);
    } else {
      if (variant === 'car') {
        likeVehicle(_id);
      } else {
        likePost(_id);
      }
      setLikeCount((prev) => prev + 1);
    }
  };

  useOutsideClick(refActionPopover, () => {
    setPostPopoverShow(false);
  });
  useOutsideClick(refShareModal, () => {
    setIsShareMenuOpen(false);
  });

  const sideBarControler = useSelector((state) => state.sideBarControler);
  const {
    cancelEventReqStat,
    deleteEventReqStat,
    deletePostReqStat,
    attendingEvents,
  } = useSelector((state) => state.posts);
  const { viewedPostIds } = useSelector((state) => state.analytics);

  useEffect(() => {
    const { canceled, attended } = attendingEvents;
    const postId = originId || _id;

    if (attended.some((el) => el === postId)) {
      setIsAttender(true);
    }

    if (canceled.some((el) => el === postId)) {
      setShowCancelAttendEvent(false);
      setIsAttender(false);
    }
  }, [attendingEvents]);

  const changeStatusEvent = () => {
    const userId = JSON.parse(getLocalStorage('user_id'));
    const eventType = type === 16 ? t('common:eventUppercase') : '';

    if (userId === process.env._ANONYMOUS_ID) {
      return `${t('event:attendUppercase')} ${eventType}`;
    }

    if (variant === 'event_details' && (data.isAdmin || event?.isAdmin)) {
      return t('routeDetails:edit');
    }

    return isAttender
      ? t('event:attendingUppercase')
      : `${t('event:attendUppercase')} ${eventType}`;
  };

  const linkDetalesPageByOrigin = () => {
    if (origin === 'profile') {
      return `${routesPath.user_details}/${originId}`;
    }
    if (origin === 'car') {
      return `${routesPath.user_details}/${creator._id}`;
    }
    if (origin === 'group') {
      return `${routesPath.user_details}/${creator._id}`;
    }
    if (origin === 'event' && routesPath.user_details) {
      return `${routesPath.user_details}/${creator?._id}`;
    }
    return '';
  };

  const followingAction = () => {
    setLoading(true);
    if (!following) {
      followUser(creator._id).then(() => {
        setLoading(false);
        setFollowing(true);
      });
    }
    if (following) {
      unfollowUser(creator._id).then(() => {
        setLoading(false);
        setFollowing(false);
      });
    }
  };

  const onCommentsClick = (id, postType) => {
    dispatch(
      openSidebarComments({
        actionType: actionTypes.OPEN_SIDEBAR_COMMENTS,
        commentsPostId: id,
        commentsType: postType,
      })
    );
  };

  const onCancelAttendEvent = (id: string) => {
    dispatch(cancelEventSubscriptionStart(id));
  };

  const onAttendEventClick = (id) => {
    if (isAttender) {
      setShowCancelAttendEvent(true);
    } else {
      dispatch(openSidebarAttendEvent(id));
    }
  };

  const postTypeTitle = () => {
    if (type === 1) {
      return t('feed:addedPhoto');
    }
    if (type === 3) {
      return t('feed:addedALinkPost');
    }
    if (type === 4) {
      return t('feed:newVehicle');
    }
    if (type === 6) {
      return t('feed:createdEvent');
    }
    if (type === 7) {
      return t('feed:joinedGroup');
    }
    if (type === 16) {
      return t('feed:isGoingToAnEvent');
    }
  };

  const getPostUrl = () => {
    const { origin: URLOrigin } = window.location;
    let url = URLOrigin;

    switch (type) {
      case 1:
      case 3:
        url = `${url}/p/${_id}`;
        break;
      case 4:
        url = `${url}/v/${originId}`;
        break;
      case 16:
      case 6:
        url = `${url}/e/${originId}`;
        break;
      case 7:
        url = `${url}/g/${originId}`;
        break;
      default:
        break;
    }

    return url;
  };

  const onPostClick = () => {
    if (isClickable) {
      router.push(getPostUrl());
    }
  };

  const urlCopyToClipboard = () => {
    setCopiedStatus(true);

    const url = getPostUrl();

    navigator.clipboard.writeText(url);
  };

  const urlCopyAsImageToClipboard = () => {
    let url;
    setCopiedImageStatus(true);

    switch (type) {
      case 1:
      case 4:
        url = (media || '') && media[0];
        break;
      case 3:
        url = linkImage || '';
        break;
      case 16:
      case 6:
        url = event?.coverPhoto || '';
        break;
      case 7:
        url = group?.coverPhoto || '';
        break;
      default:
        break;
    }

    navigator.clipboard.writeText(url);
  };

  const onReport = (reportData) => {
    switch (type) {
      case 1:
      case 3:
        dispatch(reportPostStart({ postId: _id, reportData }));
        break;
      case 4:
        dispatch(reportVehicleStart({ vehicleId: originId, reportData }));
        break;
      case 6:
      case 16:
        dispatch(reportEventStart({ eventId: originId, reportData }));
        break;
      case 7:
        dispatch(reportGroupStart({ groupId: originId, reportData }));
        break;
      default:
        break;
    }

    setIsConfirmationModalOpen(false);
    setTimeout(() => setIsAfterReportModalOpen(true), 1000);
    setTimeout(() => setIsAfterReportModalOpen(false), 5000);
  };

  const onRemoveMeFromCollaboratorsClick = (postId, collaboratorId) => {
    removeMeFromCollaborators({ postId, collaboratorId }).then((resp) => {
      setIsCollaboratorRemoved(true);
    });
  };

  const onRemoveGroupTagClick = (postId, groupId) => {
    removeGroupTag({ postId, groupId }).then((resp) => {
      setIsTagGroupRemoved(true);
    });
  };

  const onRemoveMeFromTagsClick = (postId, userId) => {
    removeMeFromTags({ postId, userId }).then((resp) => {
      resp.ok && setIsUserTagRemoved(true);
    });
  };

  const onHandlePinPost = async (postId, pin = false) => {
    try {
      const res = await action(API_NAME.PIN_EVENT_POST, {
        params: {
          eventId: router.query.id,
          postId: postId,
        },
        body: {
          pin: pin,
        },
      });
      if (res) {
        dispatch(getEventPostsStart({ id: router.query.id }));
      }
    } catch (error) {
      console.log('erorr', error);
    } finally {
      return;
    }
  };

  const RemoveGroupTag = async (postId) => {
    try {
      const res = await action(API_NAME.REMOVE_GROUP_TAGS, {
        params: {
          postId: postId,
          groupId: router?.query?.id,
        },
      });
      if (res) {
        dispatch(removeEventPostGroups(postId, router.query.id));
        
      }
    } catch (error) {
      console.log('erorr', error);
    } finally {
      return;
    }
  };

  const getPostOptionsList = () => {
    let listItemKey = 0;

    const options = [
      <li onClick={urlCopyToClipboard} key={listItemKey++}>
        {copiedStatus ? t('common:urlCopied') : t('common:copyUrl')}
      </li>,
      <li onClick={urlCopyAsImageToClipboard} key={listItemKey++}>
        {copiedImageStatus ? t('common:imageCopied') : t('common:copyAsImage')}
      </li>,
    ];
    const userId = JSON.parse(getLocalStorage('user_id'));
    let removeGroupTagOption =
      groups && !_.isEmpty(groups) && groups[0].isAdmin && !isTagGroupRemoved;

    const removeCollaboratorOption =
      collaborators &&
      !_.isEmpty(collaborators) &&
      !_.isUndefined(collaborators.find((item) => item.isMe)) &&
      !isCollaboratorRemoved;
    const removeUserTagOption =
      users &&
      !_.isEmpty(users) &&
      !_.isUndefined(users.find((item) => item.isMe)) &&
      !isUserTagRemoved;
    if (!isMine) {
      options.push(
        <li
          onClick={() =>
            anonymousClickHandler(() => setIsConfirmationModalOpen(true))
          }
          key={listItemKey++}
        >
          {t('profile:report')}
        </li>
      );
    }

    if ((type === 1 || type === 3) && isMine) {
      options.push(
        <li
          onClick={() => {
            if (type === 1) {
              dispatch(openSideBarCreatePhotoPost({ postForEditData: data }));
            } else {
              dispatch(openSideBarCreateLinkPost({ postForEditData: data }));
            }
          }}
          key={listItemKey++}
        >
          {t('event:editPost')}
        </li>
      );
    }

    if (type === 1) {
      removeGroupTagOption =
        !_.isEmpty(groups) &&
        !_.isUndefined(groups.find((item) => item.isAdmin));

      removeGroupTagOption &&
        options.push(
          <li
            onClick={() => onRemoveGroupTagClick(data._id, groups[0]._id)}
            key={listItemKey++}
          >
            {t('common:removeGroupTag')}
          </li>
        );

      removeCollaboratorOption &&
        options.push(
          <li
            onClick={() => onRemoveMeFromCollaboratorsClick(_id, userId)}
            key={listItemKey++}
          >
            {t('common:removeCollaborator')}
          </li>
        );

      removeUserTagOption &&
        options.push(
          <li
            onClick={() => onRemoveMeFromTagsClick(_id, userId)}
            key={listItemKey++}
          >
            {t('common:removeUserTagOption')}
          </li>
        );
    }

    if (type === 3) {
      removeGroupTagOption &&
        options.push(
          <li
            onClick={() => onRemoveGroupTagClick(_id, groups[0]._id)}
            key={listItemKey++}
          >
            {t('common:removeGroupTag')}
          </li>
        );

      removeCollaboratorOption &&
        options.push(
          <li
            onClick={() => onRemoveMeFromCollaboratorsClick(_id, userId)}
            key={listItemKey++}
          >
            {t('common:removeCollaborator')}
          </li>
        );

      removeUserTagOption &&
        options.push(
          <li
            onClick={() => onRemoveMeFromTagsClick(_id, userId)}
            key={listItemKey++}
          >
            {t('common:removeUserTagOption')}
          </li>
        );
    }

    if (type === 6 || type === 16 || type === 7) {
      // removeCollaboratorOption &&
      //   options.push(
      //     <li onClick={() => onRemoveMeFromCollaboratorsClick(_id, userId)}>
      //       Remove me as collaborator
      //     </li>
      //   );
    }

    if (isMine) {
      options.push(
        <li onClick={() => setIsDeletePostModalOpen(true)} key={listItemKey++}>
          {t('event:deletePost')}
        </li>
      );
    }

    if (isEventAdmin && (type === 1 || type === 3 || type === 11)) {
      options.push(
        <li
          onClick={() => {
            onHandlePinPost(_id, !data?.pin);
          }}
          key={listItemKey++}
        >
          {data?.pin ? 'Unpin Post' : 'Pin Post'}
        </li>
      );
    }

    return <ListUserUserActions>{options}</ListUserUserActions>;
  };

  // Only for post types 1 and 4 (photo, vechicle)
  const getPostImagesHeight = (width, height) => {
    // const imageWidth = size.width < 550 ? size.width : 550;
    const imageWidth = 550;
    const aspectRatio = width / height;
    const h = (imageWidth / width) * height;

    if (aspectRatio >= 3) {
      return imageWidth / 3;
    }
    if (aspectRatio <= 0.66666) {
      return imageWidth * 1.5;
    }

    return h;
  };

  const onHashtagClick = (item) => {
    const postText = _.trim(item).substring(1);
    dispatch(handleRedirect(false));
    dispatch(
      setSearchParams({
        category: 2,
        start: 0,
        limit: 20,
        q: postText,
        paramsForFilter: 0,
      })
    );

    router.push(routesPath.searchPath);
  };

  const onMentionClick = async (item) => {
    const mentionUsername = _.trim(item).substring(1);

    const userInfo = await getUserByName(mentionUsername);

    userInfo.status === 200 &&
      router.push(`${routesPath.user_details}/${userInfo.data._id}`);
  };

  const renderEventCreator = () => {
    const eventCreator = event?.creator || data.creator?.data;

    if (!eventCreator) {
      return null;
    }

    return (
      <EventCreatorWrap
        onClick={() =>
          router.push(`${routesPath.user_details}/${eventCreator._id}`)
        }
        isEventHomeTab={isEventHomeTab}
      >
        {eventCreator.profilePhoto && eventCreator.profilePhoto.trim() ? (
          <EventCreatorImageWrap
            img={eventCreator.profilePhoto.trim()}
            role="img"
            aria-label="event creator img"
          />
        ) : (
          <EmptyUserProfilePhoto
            wrapSize={24}
            userIconSize={12}
            withBorder={false}
            margin="0 8px 0 0"
          />
        )}

        <EventCreatorName>{t('event:eventBy')}</EventCreatorName>
        <EventCreatorName color={style.mainBlack.black} ml={4}>
          {eventCreator.username}
        </EventCreatorName>

        {eventCreator.isVerified && (
          <UserIconWrap>
            <VerifiedIcon color={style.mainGray.SuvaGrey} />
          </UserIconWrap>
        )}

        {eventCreator.isAmbassador && (
          <UserIconWrap>
            <AmbasadorIcon color={style.mainGray.SuvaGrey} />
          </UserIconWrap>
        )}

        {eventCreator.isDonor && (
          <UserIconWrap>
            <DonorIcon color={style.mainGray.SuvaGrey} />
          </UserIconWrap>
        )}
      </EventCreatorWrap>
    );
  };

  const renderEventAttending = () => {
    if (variant !== 'event_details') {
      return null;
    }

    const isAttendingClickable = !!data.attendersCount;
    const isInvitedClickable = !!data.invitedCount;

    const onClick = (involvedUserType, isElementClickable) => {
      if (!isElementClickable) {
        return;
      }

      dispatch(
        openSidebarInvolvedUsers({ id: _id, isAdmin, involvedUserType })
      );
    };

    return (
      <EventAttendingWrap>
        <UsersIcon2 />

        <EventAttendingItem
          ml={9}
          mr={9}
          isClickable={isAttendingClickable}
          onClick={() =>
            onClick(InvolvedUserTypes.EVENT_ATTENDING, isAttendingClickable)
          }
        >
          <EventAttendingText color={style.mainBlack.black}>
            {data.attendersCount}
          </EventAttendingText>
          <EventAttendingText ml={4}>{t('event:Attending')}</EventAttendingText>
        </EventAttendingItem>
        <EventAttendingItem
          isClickable={isInvitedClickable}
          onClick={() =>
            onClick(InvolvedUserTypes.EVENT_INVITED, isInvitedClickable)
          }
        >
          <EventAttendingText color={style.mainBlack.black}>
            {data.invitedCount}
          </EventAttendingText>
          <EventAttendingText ml={4}>{t('event:Invited')}</EventAttendingText>
        </EventAttendingItem>
      </EventAttendingWrap>
    );
  };

  const onElementVisibilityChange = (isVisible) => {
    if (viewedPostIds.includes(_id) || !isVisible || isEventHomeTab) {
      return;
    }
    dispatch(postPostViewedStart(_id));
  };

  const onPostDoubleClick = (id) => {
    if ([1, 3, 4].includes(type)) {
      anonymousClickHandler(() => handleLikeClick());
    }
  };

  const getCorrectLinkHref = (linkHref) => {
    const possibleLinkHosts = ['http', 'https', 'www'];

    let i = 0;

    while (i < possibleLinkHosts.length) {
      if (linkHref.startsWith(possibleLinkHosts[i])) {
        return linkHref;
      }

      i += 1;
    }

    return `https://${linkHref}`;
  };

  const renderLikes = (isEventLikes = false) =>
    (!event || variant === 'event_details') && (
      <SocialActivityAction
        withBorder={!isEventLikes}
        padding={isEventLikes ? '16px 0 0 0' : '16px'}
      >
        <SocialButtonsContainer>
          <SocialButtonContainer
            onClick={() => anonymousClickHandler(() => handleLikeClick())}
          >
            <LikeIcon isLike={isLikes} />
            <SocialButtonsText ml={4}>
              {t('common:likesScreenHeader')}
            </SocialButtonsText>
          </SocialButtonContainer>

          <SocialButtonContainer
            onClick={() =>
              anonymousClickHandler(() => onCommentsClick(_id, origin))
            }
            ml={24}
          >
            <MessageIcon />
            <SocialButtonsText ml={4}>
              {isEventLikes ? commentsCount : comments}
            </SocialButtonsText>
          </SocialButtonContainer>
          <SocialButtonContainer
            onClick={() =>
              anonymousClickHandler(() => setIsShareMenuOpen(!isShareMenuOpen))
            }
            ref={refShareModal}
            ml={24}
          >
            <ShareIcon />
            <SocialButtonsText ml={4}>
              {isEventLikes ? sharesCount : shares}
            </SocialButtonsText>

            {isShareMenuOpen && (
              <ShareOptions
                onCancelClick={() => setIsShareMenuOpen(false)}
                url={getPostUrl()}
                type={type}
                postId={type === 4 ? originId || _id : _id}
              />
            )}
          </SocialButtonContainer>
        </SocialButtonsContainer>

        {likeCount > 0 && (
          <SocialButtonContainer
            onClick={() =>
              dispatch(
                openSidebarLikes({
                  postId: type === 1 || type === 3 ? _id : originId || _id,
                  postType: type,
                })
              )
            }
          >
            <OutlinedLikeIconWrap>
              <LikeOutlinedIcon />
            </OutlinedLikeIconWrap>

            <SocialButtonsText ml={6}>{likeCount}</SocialButtonsText>
          </SocialButtonContainer>
        )}
      </SocialActivityAction>
    );

  return (
    <VisibilitySensor
      partialVisibility
      offset={{ bottom: 400 }}
      onChange={onElementVisibilityChange}
    >
      <PostComponentWrapper variant={variant}>
        {variant !== 'event_details' && !isEventHomeTab && (
          <PostHeaderWrap>
            <>
              {profilePhoto && profilePhoto !== ' ' ? (
                <UserPic
                  onClick={() => router.push(linkDetalesPageByOrigin())}
                  type={type}
                >
                  <Image
                    loader={() => profilePhoto}
                    src={profilePhoto}
                    alt="author post img"
                    width={48}
                    height={48}
                    unoptimized
                    priority
                  />
                </UserPic>
              ) : (
                <EmptyUserProfilePhoto
                  onClick={() => router.push(linkDetalesPageByOrigin())}
                  withBorder={type !== 1 && type !== 3}
                  withShadow={type !== 1 && type !== 3}
                  wrapSize={48}
                  userIconSize={24}
                  margin="0 16px 0 0"
                  cursor="pointer"
                />
              )}
            </>

            <HederPost>
              <BlockUserInfo>
                <Link href={linkDetalesPageByOrigin()} passHref>
                  <UserNameBlock>
                    {!username ? fullName : username}
                  </UserNameBlock>
                </Link>
                {(isVerified || creator?.isVerified) && (
                  <UserIconWrap>
                    <VerifiedIcon />
                  </UserIconWrap>
                )}
                {(isAmbassador || creator?.isAmbassador) && (
                  <UserIconWrap>
                    <AmbasadorIcon />
                  </UserIconWrap>
                )}
                {(isDonor || creator?.isDonor) && (
                  <UserIconWrap>
                    <DonorIcon />
                  </UserIconWrap>
                )}
                {type === 4 && (
                  <>
                    <UserNameBlock ml={8} fw={400} mr={5.5}>
                      {t('common:addedA')}
                    </UserNameBlock>
                    <UserNameBlock>{`${make} ${model} ${year}`}</UserNameBlock>
                  </>
                )}

                {!isMine && followingStatus === 0 && (
                  <>
                    <Dot color={style.mainGray.SuvaGrey} ml={6} mr={6} />
                    <FollowingIndicator
                      onClick={() =>
                        anonymousClickHandler(() => followingAction())
                      }
                      following={following}
                    >
                      {loading && (
                        <LoadingDotsWrap>
                          <LoadingDots dotColor="gray" />
                        </LoadingDotsWrap>
                      )}
                      {!loading && (
                        <div>
                          {following
                            ? t('profile:following')
                            : t('profile:follow')}
                        </div>
                      )}
                    </FollowingIndicator>
                  </>
                )}
              </BlockUserInfo>
              <BlockPostTime origin={origin} type={type}>
                <span>{postTypeTitle()}</span>
                <Dot ml={8} mr={8} />
                <p>{MomentFromTimeStampToNow(datetime)}</p>
              </BlockPostTime>
            </HederPost>

            <ActionsPostBlock>
              {data?.pin && (
                <Image
                  src={'/assets/img/push-pin.png'}
                  width={20}
                  height={20}
                />
              )}
              <div
                ref={refActionPopover}
                role="button"
                onClick={() => setPostPopoverShow(!postPopoverShow)}
                onKeyDown={() => setPostPopoverShow(!postPopoverShow)}
              >
                <ThreeDotsIcon colorStroke={style.mainGray.SuvaGrey} />
              </div>
            </ActionsPostBlock>
            {postPopoverShow && (
              <WrapPopover onClick={() => setPostPopoverShow(false)}>
                {getPostOptionsList()}
              </WrapPopover>
            )}

            {isDeletePostModalOpen && (
              <ConfirmationModal
                headerText={t('common:delete')}
                bodyText={t('event:AreYouSureYouWantToDeleteThisPost')}
                submitText={t('common:deleteUppercase')}
                onSubmit={() => {
                  dispatch(deletePostStart(data._id));

                  setTimeout(() => setIsDeletePostModalOpen(false), 200);
                }}
                loading={
                  data.isCanceled
                    ? deletePostReqStat === AsyncStatus.LOADING
                    : deletePostReqStat === AsyncStatus.LOADING
                }
                onCancelClick={() => setIsDeletePostModalOpen(false)}
              />
            )}
            {isConfirmationModalOpen && (
              <ConfirmationModal
                headerText={t('common:reportHeader')}
                submitText={t('settings:submitBtnUppercase')}
                onSubmit={() => alert('Submit')}
                loading={false}
                onCancelClick={() => setIsConfirmationModalOpen(false)}
                isReport
                onReportSubmit={onReport}
              />
            )}
            {isAfterReportModalOpen && (
              <AutoCloseModal text={t('common:reportFeedback')} />
            )}
          </PostHeaderWrap>
        )}

        {[1, 3, 4].some((el) => el === type) &&
          (text?.length ||
            users?.length ||
            groups?.length ||
            events?.length ||
            (type === 4 && !!data?.description?.trim().length) ||
            (origin !== 'event' && type !== 7 && location?.name)) && (
            <DescriptionWrap>
              {type === 4 && data?.description?.trim && (
                <PostText
                  text={data.description}
                  onHashtagClick={onHashtagClick}
                  onMentionClick={onMentionClick}
                />
              )}
              {!!text?.length && (
                <PostText
                  text={text || ''}
                  onHashtagClick={onHashtagClick}
                  onMentionClick={onMentionClick}
                />
              )}
              {(type === 1 || type === 3) && (
                <CollapsibleTags
                  users={users}
                  groups={groups}
                  events={events}
                />
              )}
              {origin !== 'event' &&
                type !== 4 &&
                type !== 7 &&
                location?.name && (
                  <BlockGeolocation
                    pr={16}
                    pl={16}
                    ml={4}
                    color={style.mainGray.SuvaGrey}
                  >
                    <GeoLocationIcon color={style.mainGray.SuvaGrey} />
                    <p>{location?.name}</p>
                  </BlockGeolocation>
                )}
            </DescriptionWrap>
          )}

        <MediaWrap
          origin={origin}
          type={type}
          isSliderUsed={
            mediaObjects?.length > 1 &&
            mediaObjects[0].hasOwnProperty('dimensions')
          }
          isClickable={isClickable}
          onClick={onPostClick}
          onDoubleClick={() => onPostDoubleClick(_id)}
        >
          <EventBlock isRounded={variant === 'event_details' || isEventHomeTab}>
            {event?.coverPhoto && (
              <Image
                loader={() => event?.coverPhoto}
                src={
                  event?.coverPhoto.trim()
                    ? event?.coverPhoto
                    : '/assets/img/tireTrace.png'
                }
                alt="post img"
                width={550}
                height={type === 4 ? 550 : 366}
                objectFit="cover"
                unoptimized
                priority
              />
            )}
            {variant === 'event_details' && data?.coverPhoto && (
              <>
                <Image
                  loader={() =>
                    data?.coverPhoto?.trim().length
                      ? data?.coverPhoto
                      : '/assets/img/tireTrace.png'
                  }
                  src={
                    data?.coverPhoto?.trim().length
                      ? data?.coverPhoto
                      : '/assets/img/tireTrace.png'
                  }
                  alt="post img"
                  width={550}
                  height={type === 4 ? 550 : 366}
                  objectFit="cover"
                  unoptimized
                  priority
                />

                {isAdmin && (
                  <>
                    <ThreeDotsWrap
                      ref={refActionPopover}
                      onClick={(e) => {
                        e.stopPropagation();
                        setPostPopoverShow(!postPopoverShow);
                      }}
                    >
                      <ThreeDotsIcon colorStroke="#C4C4C4" />
                    </ThreeDotsWrap>

                    {postPopoverShow && (
                      <WrapPopover
                        onClick={(e) => {
                          e.stopPropagation();
                          setPostPopoverShow(false);
                        }}
                      >
                        <ListUserUserActions>
                          <li
                            onClick={() =>
                              dispatch(openSidebarCreateEvent(data))
                            }
                          >
                            {t('event:EditEvent')}
                          </li>
                          <li onClick={() => setIsCancelModalOpen(true)}>
                            {data.isCanceled
                              ? t('event:DeleteEvent')
                              : t('event:CancelEvent')}
                          </li>
                        </ListUserUserActions>
                      </WrapPopover>
                    )}

                    {isCancelModalOpen && (
                      <ConfirmationModal
                        headerText={`${
                          data.isCanceled
                            ? t('event:delete')
                            : t('common:cancel')
                        } ${data.name}`}
                        bodyText={
                          data.isCanceled
                            ? t('event:AreYouSureYouWantToDeleteTheEvent')
                            : t('event:AreYouSureYouWantToCancelTheEvent')
                        }
                        submitText={t('settings:submitBtnUppercase')}
                        onSubmit={() => {
                          if (data.isCanceled) {
                            dispatch(deleteEventStart(data._id));
                          } else {
                            dispatch(cancelEventStart(data._id));
                          }

                          setTimeout(() => setIsCancelModalOpen(false), 200);
                        }}
                        loading={
                          data.isCanceled
                            ? deleteEventReqStat === AsyncStatus.LOADING
                            : cancelEventReqStat === AsyncStatus.LOADING
                        }
                        onCancelClick={() => setIsCancelModalOpen(false)}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </EventBlock>
          {type === 1 && (
            <>
              {mediaObjects?.length > 0 &&
              mediaObjects[0].hasOwnProperty('dimensions') ? (
                <Carousel
                  dynamicHeight
                  showThumbs={false}
                  showStatus={false}
                  renderArrowPrev={(clickHandler, hasPrev) =>
                    hasPrev && (
                      <ArrowPrev
                        onClick={(e) => {
                          e.stopPropagation();
                          clickHandler();
                        }}
                      >
                        <>
                          <svg
                            width="7"
                            height="10"
                            viewBox="0 0 7 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.66602 9L5.66602 5L1.66602 0.999998"
                              stroke="white"
                              strokeWidth="1.4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </>
                      </ArrowPrev>
                    )
                  }
                  renderArrowNext={(clickHandler, hasPrev) =>
                    hasPrev && (
                      <ArrowNext
                        onClick={(e) => {
                          e.stopPropagation();
                          clickHandler();
                        }}
                      >
                        <>
                          <svg
                            width="7"
                            height="10"
                            viewBox="0 0 7 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.66602 9L5.66602 5L1.66602 0.999998"
                              stroke="white"
                              strokeWidth="1.4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </>
                      </ArrowNext>
                    )
                  }
                  renderIndicator={(clickHandler, isSelected) => (
                    <>
                      {mediaObjects?.length > 1 &&
                      mediaObjects[0].hasOwnProperty('dimensions') ? (
                        <CarouselDots
                          onClick={(e) => {
                            e.stopPropagation();
                            clickHandler(e);
                          }}
                          isSelected={isSelected}
                        />
                      ) : null}
                    </>
                  )}
                >
                  {mediaObjects?.length > 0 &&
                    mediaObjects[0].hasOwnProperty('dimensions') &&
                    mediaObjects.map((el, i) => {
                      if (el?.dimensions) {
                        return (
                          <CarouselWrap
                            height={getPostImagesHeight(
                              el.dimensions.width,
                              el.dimensions.height
                            )}
                            key={`media-${i}`}
                          >
                            <Image
                              loader={() => el.url}
                              src={el.url}
                              alt="post img"
                              width={550}
                              height={getPostImagesHeight(
                                el.dimensions.width,
                                el.dimensions.height
                              )}
                              objectFit={
                                el.dimensions.width < 550
                                  ? 'contain'
                                  : 'initial'
                              }
                              unoptimized
                              priority
                            />
                          </CarouselWrap>
                        );
                      }
                      return (
                        <WheelMarkIconWrap>
                          <Image
                            src="/assets/img/tireTrace.png"
                            alt="tire trace"
                            width={515}
                            height={366}
                            unoptimized
                            priority
                          />
                        </WheelMarkIconWrap>
                      );
                    })}
                </Carousel>
              ) : (
                <CoverImg>
                  <WheelMarkIconWrap>
                    <Image
                      src="/assets/img/tireTrace.png"
                      alt="tire trace"
                      width={515}
                      height={366}
                      unoptimized
                      priority
                    />
                  </WheelMarkIconWrap>
                </CoverImg>
              )}
            </>
          )}
          {type === 4 && (
            <>
              {mediaObjects?.length > 0 &&
              mediaObjects[0].hasOwnProperty('dimensions') ? (
                <Carousel
                  dynamicHeight
                  showThumbs={false}
                  showStatus={false}
                  renderArrowPrev={(clickHandler, hasPrev) =>
                    hasPrev && (
                      <ArrowPrev
                        onClick={(e) => {
                          e.stopPropagation();
                          clickHandler();
                        }}
                      >
                        <>
                          <svg
                            width="7"
                            height="10"
                            viewBox="0 0 7 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.66602 9L5.66602 5L1.66602 0.999998"
                              stroke="white"
                              strokeWidth="1.4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </>
                      </ArrowPrev>
                    )
                  }
                  renderArrowNext={(clickHandler, hasPrev) =>
                    hasPrev && (
                      <ArrowNext
                        onClick={(e) => {
                          e.stopPropagation();
                          clickHandler();
                        }}
                      >
                        <>
                          <svg
                            width="7"
                            height="10"
                            viewBox="0 0 7 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.66602 9L5.66602 5L1.66602 0.999998"
                              stroke="white"
                              strokeWidth="1.4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </>
                      </ArrowNext>
                    )
                  }
                  renderIndicator={(clickHandler, isSelected) => (
                    <>
                      {mediaObjects?.length > 1 &&
                      mediaObjects[0].hasOwnProperty('dimensions') ? (
                        <CarouselDots
                          onClick={(e) => {
                            e.stopPropagation();
                            clickHandler(e);
                          }}
                          isSelected={isSelected}
                        />
                      ) : null}
                    </>
                  )}
                >
                  {mediaObjects?.length > 0 &&
                    mediaObjects[0].hasOwnProperty('dimensions') &&
                    mediaObjects.map((el, i) => (
                      <CarouselWrap
                        height={getPostImagesHeight(
                          el.dimensions.width,
                          el.dimensions.height
                        )}
                        key={`media-${i}`}
                      >
                        <Image
                          loader={() => el.url}
                          src={el.url}
                          alt="post img"
                          width={550}
                          height={getPostImagesHeight(
                            el.dimensions.width,
                            el.dimensions.height
                          )}
                          objectFit={
                            el.dimensions.width < 550 ? 'contain' : 'initial'
                          }
                          priority
                          unoptimized
                        />
                      </CarouselWrap>
                    ))}
                </Carousel>
              ) : (
                <CoverImg>
                  <WheelMarkIconWrap>
                    <Image
                      src="/assets/img/tireTrace.png"
                      alt="tire trace"
                      width={515}
                      height={366}
                      unoptimized
                      priority
                    />
                  </WheelMarkIconWrap>
                </CoverImg>
              )}
              {vehicle && (
                <CarInfoBlock isClickable={isClickable} onClick={onPostClick}>
                  <div>{`${make} ${model} ${year}`}</div>
                  {(nickname || data.nickname) && (
                    <span>{nickname || data.nickname}</span>
                  )}
                </CarInfoBlock>
              )}
            </>
          )}
          {type === 3 && (
            <LinkWrap
              href={getCorrectLinkHref(link)}
              target="_blank"
              rel="noreferrer"
            >
              {regexMap.urlImg.test(linkImage) &&
              linkImage !== DEFAULT_LINK_POST_PLACEHOLDER_IMAGE &&
              linkImage.startsWith('https') ? (
                <LinkImageBackground>
                  <Image
                    loader={() => linkImage}
                    src={linkImage}
                    alt="post img"
                    width={550}
                    height={366}
                    objectFit="cover"
                    unoptimized
                    priority
                  />
                </LinkImageBackground>
              ) : (
                <CoverImg>
                  <WheelMarkIconWrap>
                    <Image
                      src="/assets/img/tireTrace.png"
                      alt="tire trace"
                      width={550}
                      height={366}
                      unoptimized
                      priority
                    />
                  </WheelMarkIconWrap>
                </CoverImg>
              )}

              <LinkBlockWrap>
                <LinkTitle>{linkTitle}</LinkTitle>
                <LinkText>{linkHost}</LinkText>
              </LinkBlockWrap>
            </LinkWrap>
          )}
          {type === 7 && (
            <>
              {regexMap.urlImg.test(group.coverPhoto) ? (
                <Image
                  loader={() => group.coverPhoto}
                  src={group.coverPhoto}
                  alt="post img"
                  width={550}
                  height={366}
                  objectFit="cover"
                  unoptimized
                  priority
                />
              ) : (
                <CoverImg>
                  <WheelMarkIconWrap>
                    <Image
                      src="/assets/img/tireTrace.png"
                      alt="tire trace"
                      width={550}
                      height={366}
                      unoptimized
                      priority
                    />
                  </WheelMarkIconWrap>
                </CoverImg>
              )}
            </>
          )}
        </MediaWrap>

        {type !== 7 || variant === 'event_details' ? (
          <SocialActivityBlock origin={linkImage && linkTitle ? 'link' : null}>
            {type !== 7 && variant !== 'event_details' && renderLikes()}

            {event && (
              <EventDetailsBlock
                isCollaboratorsExists={event && collaborators?.length > 0}
              >
                <ClickableArea isClickable={isClickable} onClick={onPostClick}>
                  <EventPeriod>
                    <CupIcon width="16" height="16" />
                    <p>
                      {moment(event?.startDate || data?.startDate).format(
                        'DD MMM hh:mmA'
                      )}
                    </p>
                    <p>{' - '}</p>
                    <p>
                      {moment(event?.endDate || data?.endDate).format(
                        'DD MMM hh:mmA'
                      )}
                    </p>
                  </EventPeriod>

                  <EventNameText>{event.name || data.name}</EventNameText>
                  {type !== 7 && (
                    <>
                      {(event.location || data.address) && (
                        <BlockGeolocation
                          event={!!event}
                          fs={13}
                          mb={16}
                          ml={8}
                        >
                          <GeoLocationIcon />
                          <p>{event?.location?.address || data.address}</p>
                        </BlockGeolocation>
                      )}
                    </>
                  )}
                </ClickableArea>

                {type === 16 && renderEventCreator()}

                {renderEventAttending()}

                {!isEventHomeTab && (
                  <EventButtons origin={type === 6 ? 'create_event' : null}>
                    {(event.isCanceled || data.isCanceled) &&
                    variant === 'event_details' ? (
                      <CanceledEventText>
                        {t('event:eventIsCanceled')}
                      </CanceledEventText>
                    ) : (
                      <>
                        {!(event.isCanceled || data.isCanceled) && (
                          <RectangleButton
                            text={changeStatusEvent()}
                            backgroundColor={
                              data.isAdmin || isAttender || event?.isAdmin
                                ? '#ffffff'
                                : style.mainYellow.yellow
                            }
                            color={
                              data.isAdmin || isAttender || event?.isAdmin
                                ? style.mainYellow.yellow
                                : '#ffffff'
                            }
                            border={
                              data.isAdmin || isAttender || event?.isAdmin
                                ? `1px solid ${style.mainYellow.yellow}`
                                : 'none'
                            }
                            cursor={
                              variant !== 'event_details' && event.isAdmin
                                ? 'auto'
                                : 'pointer'
                            }
                            onClick={() =>
                              anonymousClickHandler(() => {
                                if (data.isAdmin) {
                                  dispatch(openSidebarCreateEvent(data));
                                } else if (
                                  variant !== 'event_details' &&
                                  event.isAdmin
                                ) {
                                  return null;
                                } else {
                                  onAttendEventClick(originId || _id);
                                }
                              })
                            }
                          />
                        )}
                        <RectangleButton
                          text={
                            variant === 'event_details'
                              ? t('event:inviteUppercase')
                              : type === 6
                              ? t('common:moreInfo')
                              : t('event:eventDetailsUppercase')
                          }
                          backgroundColor={style.mainBlue.blue}
                          color="#fff"
                          onClick={() => {
                            if (variant === 'event_details') {
                              anonymousClickHandler(() => null);
                            } else {
                              anonymousClickHandler(() =>
                                router.push(
                                  `${routesPath.event_details}/${originId}`
                                )
                              );
                            }
                          }}
                        />
                        {variant === 'event_details' &&
                          data.isChat &&
                          (data.isAdmin || isAttender) && (
                            <MessageButtonWrap>
                              <RectangleButton
                                color={style.mainBlack.black}
                                text=""
                                onClick={() =>
                                  anonymousClickHandler(() => {
                                    dispatch(getRoomInfoStart(_id));
                                    dispatch(
                                      openSidebarChatConversation({
                                        chatId: _id,
                                      })
                                    );
                                  })
                                }
                                backgroundColor="#F6F6F6"
                                icon={<ChatIcon />}
                              />
                            </MessageButtonWrap>
                          )}
                      </>
                    )}
                  </EventButtons>
                )}
                {renderLikes(true)}
              </EventDetailsBlock>
            )}

            {event && collaborators?.length > 0 && (
              <CollaboratorsWrap padding="0 22px">
                <CollaboratorsHeading>
                  {t('photo:Collaborators')}
                </CollaboratorsHeading>
                <ColaboratorsSlider vehicle={collaborators} />
              </CollaboratorsWrap>
            )}
          </SocialActivityBlock>
        ) : (
          <>
            <GroupHeaderWrap
              isClickable={isClickable}
              onClick={onPostClick}
              isCollaboratorsExists={collaborators?.length > 0}
            >
              <Link href={`${routesPath.group_details}/${originId}`}>
                {group.coverPhoto.trim() ? (
                  <GroupCoverPhoto img={group.coverPhoto} />
                ) : (
                  <EmptyUserProfilePhoto
                    wrapSize={48}
                    userIconSize={24}
                    margin="0 16px 0 0"
                    cursor="pointer"
                  />
                )}
              </Link>

              <GroupNamesWrap>
                <Link href={`${routesPath.group_details}/${originId}`}>
                  <GroupDescription>
                    {group.username}
                    {privacy > 0 && !following && (
                      <LockIcon color={style.mainYellow.yellow} />
                    )}
                  </GroupDescription>
                </Link>

                <GroupDescriptionName>{group.name}</GroupDescriptionName>
              </GroupNamesWrap>
            </GroupHeaderWrap>

            {collaborators?.length > 0 && (
              <CollaboratorsWrap padding="0 20px">
                <CollaboratorsHeading>
                  {t('photo:Collaborators')}
                </CollaboratorsHeading>
                <ColaboratorsSlider vehicle={collaborators} />
              </CollaboratorsWrap>
            )}
          </>
        )}

        {showCancelAttendEvent && (
          <ConfirmationModal
            headerText={t('group:unjoin')}
            bodyText={t('event:AreYouSureYouWantToUnattendTheEvent')}
            submitText={t('group:leaveUppercase')}
            onSubmit={() => onCancelAttendEvent(originId || _id)}
            onCancelClick={() => setShowCancelAttendEvent(false)}
          />
        )}
      </PostComponentWrapper>
    </VisibilitySensor>
  );
};

export async function getStaticProps() {
  const res = await fetch(`https://.../data`);
  const data = await res.json();

  if (!data) {
    return {
      notFound: true,
    };
  }

  return {
    props: { data },
  };
}
